import {
  CreateLoginFlowUseCase,
  LoginByEmailPasswordUseCase,
  LoginByMagicTokenUseCase, LoginByOAuthCodeUseCase,
  LogoutUseCase
} from "../../../application";
import {authTypes} from "../../../../../di/types";
import {LoginFlowType} from "../../../enums";
import {LoginFlow} from "../../../../../models/login";
import {useAuthSession} from "../../../../../../nuxt/layers/auth/composables/useAuthSession";
import {OauthProvider} from "../../../enums/oauthProvider";

const loginFlow = ref<LoginFlow>(null);

export function useLogin() {
  const { $visitorId, $container } = useNuxtApp();
  const config = useRuntimeConfig();
  const { fetchSession, resetSession, session } = useAuthSession();
  const createLoginFlowUseCase: CreateLoginFlowUseCase = $container.get<CreateLoginFlowUseCase>(authTypes.CreateLoginFlowUseCase);
  const loginByEmailPasswordUseCase: LoginByEmailPasswordUseCase = $container.get<LoginByEmailPasswordUseCase>(authTypes.LoginByEmailPasswordUseCase);
  const loginByMagicTokenUseCase: LoginByMagicTokenUseCase = $container.get<LoginByMagicTokenUseCase>(authTypes.LoginByMagicTokenUseCase);
  const loginByOAuthCodeUseCase: LoginByOAuthCodeUseCase = $container.get<LoginByOAuthCodeUseCase>(authTypes.LoginByOAuthCodeUseCase);
  const logoutUseCase: LogoutUseCase = $container.get<LogoutUseCase>(authTypes.LogoutUseCase);

  const isLoading = ref(false);
  const localePath = useLocalePath();

  async function createEmailPasswordLoginFlow() {
    isLoading.value = true;

    try {
      loginFlow.value = await createLoginFlowUseCase.byEmailPassword($visitorId);
      setTimeout(() => {
        loginFlow.value = null;
      }, 1000 * 60 * 10);
    } catch (e) {

    } finally {
      isLoading.value = false;
    }
  }

  async function createPasswordlessLoginFlow(email: string) {
    isLoading.value = true;

    try {
      loginFlow.value = await createLoginFlowUseCase.byEmail($visitorId, email);
      setTimeout(() => {
        loginFlow.value = null;
      }, 1000 * 60 * 3);
    } catch (e) {

    } finally {
      isLoading.value = false;
    }
  }

  async function createOAuthLoginFlow(provider: OauthProvider): Promise<string> {
    isLoading.value = true;

    try {
      return await createLoginFlowUseCase.byOauth($visitorId, provider);
    } catch (e) {

    } finally {
      isLoading.value = false;
    }
  }

  async function loginByEmailPassword(email: string, password: string) {
    isLoading.value = true;

    try {
      await loginByEmailPasswordUseCase.execute(loginFlow.value.flowId, email, password);
      await fetchSession();
      return session;
    } finally {
      isLoading.value = false;
    }
  }

  async function loginByMagicToken(flowId: string, token: string) {
    isLoading.value = true;

    try {
      await loginByMagicTokenUseCase.execute(flowId, token, $visitorId);
      await fetchSession();
      return session;
    } finally {
      isLoading.value = false;
    }
  }

  async function loginByOAuthCode(flowId: string, authCode: string) {
    await loginByOAuthCodeUseCase.execute(flowId, $visitorId, authCode);
    await fetchSession();
    return session;
  }

  async function logout(withRedirect: boolean = true) {
    isLoading.value = true;

    try {
      await logoutUseCase.execute();
      if (withRedirect) {
        await navigateTo(localePath(config.public.auth.signInRoute));
        setTimeout(() => {
          resetSession();
        }, 1000);
      } else {
        await resetSession();
      }
    } catch (e) {

    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    loginFlow,
    createEmailPasswordLoginFlow,
    createPasswordlessLoginFlow,
    createOAuthLoginFlow,
    loginByEmailPassword,
    loginByMagicToken,
    loginByOAuthCode,
    logout,
  }
}
